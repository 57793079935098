import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getCompanyByIdApi,
  sendEmailApi,
} from "../../../API/methods/companiesFlow";
import Navbar from "../../layout/Navbar";
import { IoArrowBackCircleOutline } from "react-icons/io5";

const ViewCompanyDetails = () => {
  const [company, setCompany] = useState({
    name: "",
    domainName: "",
    description: "",
    imageURL: "",
    active: true,
    paymentDetails: "",
    amount: "",
    receivedBy: "",
    email: "",
    address: {
      buidingNumber: "",
      street: "",
      city: "",
      state: "",
      code: "",
      country: "",
      phoneNumber: "",
      faxNumber: "",
      emailId: "",
      mobileNumber: "",
      type: "",
    },
  });
  const {
    name,
    domainName,
    description,
    imageURL,
    address,
    buidingNumber,
    street,
    city,
    state,
    email,
    active,
    code,
    country,
    phoneNumber,
    faxNumber,
    emailId,
    mobileNumber,
    type,
    amount,
    receivedBy,
    paymentDetails,
  } = company;
  const { id } = useParams();
  useEffect(() => {
    loadcompany();
  }, []);
  const loadcompany = async () => {
    const result = await getCompanyByIdApi(id);
    console.log(result.data);
    setCompany(result.data);
  };

  const sendEmail = async () => {
    const result = await sendEmailApi(id, company);
    if (result.status == 200) {
      alert("Email sent successfully to  the company" + id);
    }
  };
  return (
    <div className="container mt-80">
      <Navbar></Navbar>
      <div className="w-150 mx-auto shadow p-5">
        <div className="d-flex flex-row">
          <Link to="/companies">
            <IoArrowBackCircleOutline size={35} color="#2bc87f" />
          </Link>
          <h3 className="text-center mb-4 ml-2">Company</h3>
        </div>
        <form>
          <div class="form-group row">
            <h6 class="col-sm-2 col-form-label">Basic Details</h6>
          </div>
          <div className="row">
            <div className="col">
              <div class="form-group row">
                <label for="name" class="col-sm-3 col-form-label">
                  Company
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Company"
                    name="name"
                    value={name}
                    style={{ width: "300px" }}
                    required
                    readOnly
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="domainName" class="col-sm-3 col-form-label">
                  Domain
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="www.companyname.com"
                    name="domainName"
                    value={domainName}
                    style={{ width: "300px" }}
                    readOnly
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="email" class="col-sm-3 col-form-label">
                  E-mail
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="eample@mail.com"
                    name="email"
                    value={email}
                    style={{ width: "300px" }}
                    required
                    readOnly
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="description" class="col-sm-3 col-form-label">
                  Description:{" "}
                </label>
                <div class="col-sm-9">
                  <textarea
                    type="text"
                    rows={3}
                    className="form-control"
                    placeholder="Enter description"
                    name="description"
                    value={description}
                    style={{ width: "300px" }}
                    required
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col">
              {imageURL && (
                <img
                  src={imageURL}
                  className="col-5"
                  alt="No Image"
                  height={200}
                  width={200}
                  style={{ objectFit: "contain" }}
                />
              )}
              <div class="form-group row">
                <label for="active" class="col-sm-3 col-form-label">
                  Active
                </label>
                <div class="col-sm-9">
                  <input
                    type="checkbox"
                    name="status"
                    value="status"
                    checked={active}
                    style={{ width: 30 }}
                    disabled="disabled"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mt-4">
            <h6 class="col-sm-4 col-form-label">Address Information</h6>
          </div>
          <div className="row">
            <div className="col">
              <div class="form-group row">
                <label for="buidingNumber" class="col-sm-3 col-form-label">
                  Building No.
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Building No."
                    name="buidingNumber"
                    value={address.buildingNumber}
                    style={{ width: "300px" }}
                    required
                    readOnly
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="emailId" class="col-sm-3 col-form-label">
                  E-mail
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="example@mail.com"
                    name="emailId"
                    value={address.emailId}
                    style={{ width: "300px" }}
                    required
                    readOnly
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="street" class="col-sm-3 col-form-label">
                  Street
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street name"
                    name="street"
                    value={address.street}
                    style={{ width: "300px" }}
                    required
                    readOnly
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="city" class="col-sm-3 col-form-label">
                  City
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City "
                    name="city"
                    value={address.city}
                    style={{ width: "300px" }}
                    required
                    readOnly
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="state" class="col-sm-3 col-form-label">
                  State
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State "
                    name="state"
                    value={address.state}
                    style={{ width: "300px" }}
                    required
                    readOnly
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="code" class="col-sm-3 col-form-label">
                  Area Code
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Area Code"
                    name="code"
                    value={address.code}
                    style={{ width: "300px" }}
                    required
                    readOnly
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="country" class="col-sm-3 col-form-label">
                  Country
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Country "
                    name="country"
                    value={address.country}
                    style={{ width: "300px" }}
                    required
                    readOnly
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="phoneNumber" class="col-sm-3 col-form-label">
                  Phone No.
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={address.phoneNumber}
                    style={{ width: "300px" }}
                    required
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div class="form-group row">
                <label for="faxNumber" class="col-sm-3 col-form-label">
                  Fax No.
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Fax Number"
                    name="faxNumber"
                    value={address.faxNumber}
                    style={{ width: "300px" }}
                    readOnly
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="type" class="col-sm-3 col-form-label">
                  Address Type
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address Type"
                    name="type"
                    value={address.type}
                    style={{ width: "300px" }}
                    required
                    readOnly
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="mobileNumber" class="col-sm-3 col-form-label">
                  Mobile No.
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile Number"
                    name="mobileNumber"
                    value={address.mobileNumber}
                    style={{ width: "300px" }}
                    readOnly
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="amount" class="col-sm-3 col-form-label">
                  Amount
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Total Amount"
                    name="amount"
                    value={amount}
                    style={{ width: "300px" }}
                    readOnly
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="receivedBy" class="col-sm-3 col-form-label">
                  Payment Received By{" "}
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Amount Received By"
                    name="receivedBy"
                    value={receivedBy}
                    style={{ width: "300px" }}
                    readOnly
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="paymentDetails" class="col-sm-3 col-form-label">
                  Payment done Via
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Amount payment Details"
                    name="paymentDetails"
                    value={paymentDetails}
                    style={{ width: "300px" }}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="form-group row">
          <button
            className="btn btn-primary btn-m mt-4"
            onClick={(e) => sendEmail(e)}
          >
            Send e-mail
          </button>
        </div>
      </div>
    </div>
  );
};
export default ViewCompanyDetails;
