import React, { useState } from "react";
import "./App.css";
import "./index.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PageNotFound from "./components/pages/PageNotFound";
import CreateCompany from "./components/pages/Companies/CreateCompany";
import EditCompany from "./components/pages/Companies/EditCompany";
import ViewCompanyDetails from "./components/pages/Companies/ViewCompanyDetails";
import Login from "./components/pages/Login";
import CreatePlan from "./components/pages/plans/CreatePlan";
import Logout from "./components/pages/Logout";
import SubscriptionManagement from "./components/pages/plans/SubscriptionManagement";
import CompaniesManagement from "./components/pages/Companies/CompaniesManagement";
import EditPlan from "./components/pages/plans/EditPlan";
import Subscriptions from "./components/pages/Subscriptions";
import ViewPlanDetails from "./components/pages/plans/ViewPlanDetails";
import ProtectedRoute from "./components/pages/ProtectedRoute";
import Loader from "./components/Loader";
import { useIsLoading } from "./Context/IsLoadingContext";
import AddAdmin from "./components/pages/Admins/AddAdmin";
import AllAdmins from "./components/pages/Admins/AllAdmins";

function App() {
  const isLoading = useIsLoading();
  return (
    <Router>
      <div className="App">
        {isLoading && <Loader />}
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <ProtectedRoute
            exact
            path="/addnewcompany"
            component={CreateCompany}
          />
          <ProtectedRoute exact path="/addPlan" component={CreatePlan} />
          <ProtectedRoute
            exact
            path="/editcompany/:id"
            component={EditCompany}
          />
          <ProtectedRoute
            exact
            path="/viewcompany/:id"
            component={ViewCompanyDetails}
          />
          <ProtectedRoute
            exact
            path="/plans"
            component={SubscriptionManagement}
          />
          <ProtectedRoute
            exact
            path="/companies"
            component={CompaniesManagement}
          />
          <ProtectedRoute
            exact
            path="/subscriptions"
            component={Subscriptions}
          />
          <ProtectedRoute exact path="/logout" component={Logout} />
          <ProtectedRoute
            exact
            path="/viewplan/:id"
            component={ViewPlanDetails}
          />
          <ProtectedRoute exact path="/editplan/:id" component={EditPlan} />

          <ProtectedRoute exact path="/addadmin/:id" component={AddAdmin} />
          <ProtectedRoute
            exact
            path="/viewAllAdmins"
            component={AllAdmins}
          />
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </Router>
  );
}
export default App;
