import React  from 'react';

const PageNotFound =() =>{
    return (
     <div className ="not-found">
            <h1 className ="display-1">
                       <div>Oops! Page Not Found. Please contact EVITPL customer support.</div>
            </h1>
        </div>

    );
};
export default PageNotFound;