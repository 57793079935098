import React, { useState, useEffect } from "react";
import {
  getCompanyApi,
  updateCompanyApi,
} from "../../API/methods/companiesFlow";
import { getPlansApi } from "../../API/methods/subscriptionFLow";
import { Link } from "react-router-dom";
import Navbar from "../layout/Navbar";
import { useHistory } from "react-router-dom";
import "../../index.css";
import history from "react-router-dom";
import { useUpdateIsLoading } from "../../Context/IsLoadingContext";

const Subscriptions = () => {
  const updateIsLoading = useUpdateIsLoading();
  let history = useHistory();
  const [companies, setCompanies] = useState([]);
  const [changesInCompanies, setChanges] = useState([]);
  const [plans, setPlan] = useState([]);
  // const [company, setCompany] = useState({
  //   name: "",
  //   domainName: "",
  //   description: "",
  //   active: false,
  //   email: "",
  //   expire: null,
  //   address: {
  //     buidingNumber: "",
  //     street: "",
  //     city: "",
  //     state: "",
  //     code: "",
  //     country: "",
  //     phoneNumber: "",
  //     faxNumber: "",
  //     emailId: "",
  //     mobileNumber: "",
  //     type: "",
  //   },
  //   plan: {
  //     planId: "",
  //   },
  // });
  // const {
  //   expire,
  //   name,
  //   domainName,
  //   description,
  //   address,
  //   buidingNumber,
  //   street,
  //   city,
  //   state,
  //   email,
  //   active,
  //   code,
  //   country,
  //   phoneNumber,
  //   faxNumber,
  //   emailId,
  //   mobileNumber,
  //   type,
  //   plan,
  // } = company;

  useEffect(() => {
    loadPlans();
  }, []);

  function onPlanChange(event, company) {
    let newArray = [];
    changesInCompanies.map((item) => {
      if (item.name === company.name) {
        let planData = plans.filter((item2) => {
          return item2.planId == event.target.value;
        });
        const newData = {
          ...item,
          planId: planData[0].planId,
        };
        newArray.push(newData);
      } else {
        newArray.push(item);
      }
    });
    setChanges(newArray);
  }

  function onDateChange(event, companyData) {
    let newArray = [];
    changesInCompanies.map((item) => {
      if (item.name === companyData.name) {
        const newData = { ...item, expire: event.target.value };
        newArray.push(newData);
      } else {
        newArray.push(item);
      }
    });
    setChanges(newArray);
  }

  const updateCompany = async (company) => {
    let Data = changesInCompanies.filter((item) => {
      return company.name === item.name;
    });
    const companyData = Data[0];

    const result = await updateCompanyApi(company.name, companyData);
    if (result.status === 200) {
      alert("Subscription Updated");
    }
    loadCompanies(plans);
  };

  const loadCompanies = async (Plans) => {
    updateIsLoading(true);
    const result = await getCompanyApi();
    if (result) {
      let cmpData = result.data.reverse();
      let companiesWithPlanName = [];
      cmpData.map((company) => {
        let planName = "";
        if (company.planId != 0) {
          planName = Plans.filter((plan) => company.planId === plan.planId);
          planName = planName[0].planName;
        }
        companiesWithPlanName.push({ ...company, planName });
      });
      setCompanies(companiesWithPlanName);
      setChanges(companiesWithPlanName);
    }
    updateIsLoading(false);
  };

  const loadPlans = async () => {
    updateIsLoading(true);
    const result = await getPlansApi();
    try {
      if (result) {
        let plans = result.data.reverse();
        setPlan(plans);
        loadCompanies(plans);
      }
    } catch (error) {
      updateIsLoading(false);
    }
  };

  return (
    <div className="container mt-80">
      <Navbar></Navbar>
      {/* <h5 className="heading">Plan Subscriptions</h5> */}
      <div className="py-4">
        {companies.length !== 0 && (
          <table className="table border shadow">
            <thead className="thead-light">
              <tr>
                <th scope="col">Company Name</th>
                <th scope="col">Current Plan</th>
                <th scope="col">Current Expiration Date</th>
                <th scope="col">New Expiration Date</th>
                <th scope="col">New Plan</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company, index) => {
                let DATE = "";
                if (company.expire) {
                  DATE = JSON.stringify(company.expire).substring(1, 11);
                  DATE = new Date(DATE);
                  DATE =
                    DATE.getDate() +
                    "-" +
                    (DATE.getMonth() + 1) +
                    "-" +
                    DATE.getFullYear();
                }
                return (
                  <tr key={company.name}>
                    <td>
                      <b>
                        <font color="#3361FF">{company.name}</font>
                      </b>
                    </td>
                    <td>{company.planName ? company.planName : ""}</td>

                    <td>{DATE}</td>

                    <td>
                      <input
                        type="date"
                        name="expire"
                        id="expire"
                        onChange={(e) => onDateChange(e, company)}
                      />
                    </td>
                    <td>
                      <select
                        id="subscriptionPlan"
                        onChange={(e) => onPlanChange(e, company)}
                        style={{ width: 150 }}
                      >
                        <option value data-isdefault="true"></option>
                        {plans.map((plan, index) => (
                          <option
                            value={plan.planId}
                            name={plan.planName}
                            key={plan.planId}
                          >
                            {plan.planName}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <button
                        className="btn btn-light btn-sm"
                        onClick={() => updateCompany(company)}
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Subscriptions;
