import React, { useState } from "react";
import { createAdminApi } from "../../../API/methods/adminFlow";
import { useHistory, Link, useParams } from "react-router-dom";
import Navbar from "../../layout/Navbar";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useUpdateIsLoading } from "../../../Context/IsLoadingContext";

const AddAdmin = () => {
  let history = useHistory();
  const updateIsLoading = useUpdateIsLoading();
  const comapnyname = useParams();
  const [admin, setAdmin] = useState({
    company: comapnyname.id,
    password: "",
    role: "ADMIN",
    email: "",
  });
  const [isVisible, setIsVisible] = useState(false);

  const { company, password, role, email } = admin;
  const onInputChange = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    updateIsLoading(true);
    createAdminApi(admin)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          history.push("/companies");
        }
      })
      .finally(() => {
        updateIsLoading(false);
      });
  };

  return (
    <div className="container mt-80">
      <Navbar />
      <div className="w-150 mx-auto shadow p-5">
        <div className="d-flex flex-row">
          <Link to="/companies">
            <IoArrowBackCircleOutline size={35} color="#2bc87f" />
          </Link>
          <h3 className="text-center mb-4 ml-2">Admin</h3>
        </div>
        <form onSubmit={(e) => onSubmit(e)}>
          <div class="form-group row">
            <label for="company" class="col-sm-2 col-form-label">
              Company Name
            </label>
            <div class="col-sm-10">
              <input
                type="text"
                name="company"
                className="form-control"
                placeholder="Company Name"
                value={company}
                style={{ width: "300px" }}
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="role" class="col-sm-2 col-form-label">
              Role
            </label>
            <div class="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="User Role"
                name="role"
                value={role}
                style={{ width: "300px" }}
                onChange={(e) => onInputChange(e)}
                readOnly
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="cpoCount" class="col-sm-2 col-form-label">
              Email
            </label>
            <div class="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="example@mail.com"
                name="email"
                style={{ width: "300px" }}
                value={email}
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="description" class="col-sm-2 col-form-label">
              Password
            </label>
            <div class="col-sm-4 input-group d-flex justify-content-end">
              <input
                type={!isVisible ? "password" : "text"}
                className="form-control pr-5"
                placeholder="Enter Password"
                name="password"
                // style={{ width: 250 }}
                value={password}
                onChange={(e) => onInputChange(e)}
                required
              />
              {!isVisible ? (
                <AiFillEye
                  onClick={() => setIsVisible(!isVisible)}
                  className="password-icon"
                />
              ) : (
                <AiFillEyeInvisible
                  onClick={() => setIsVisible(!isVisible)}
                  className="password-icon"
                />
              )}
            </div>
          </div>
          <button className="btn btn-secondary">Add Admin</button>
        </form>
      </div>
    </div>
  );
};

export default AddAdmin;
