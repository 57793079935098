import React, { useState } from "react";
import { createPlanApi } from "../../../API/methods/planFlow";
import { useHistory, Link } from "react-router-dom";
import Navbar from "../../layout/Navbar";
import { IoArrowBackCircleOutline } from "react-icons/io5";

const CreatePlan = () => {
  let history = useHistory();
  const [plan, setPlan] = useState({
    planName: "",
    cpoCount: "",
    description: "",
    chargerCount: "",
    // company: "",
  });

  const { planName, cpoCount, description, chargerCount, company } = plan;
  const onInputChange = (e) => {
    setPlan({ ...plan, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    createPlanApi(plan)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          history.push("/plans");
        }
      })
      .catch((err) => window.alert("Internal Server Error"));
  };

  return (
    <div className="container mt-80">
      <Navbar />
      <div className="w-150 mx-auto shadow p-5">
        <div className="d-flex flex-row">
          <Link to="/plans">
            <IoArrowBackCircleOutline size={35} color="#2bc87f" />
          </Link>
          <h3 className="text-center mb-4 ml-2">Plan</h3>
        </div>
        <form onSubmit={(e) => onSubmit(e)}>
          {/* <div class="form-group row">
            <label for="planName" class="col-sm-2 col-form-label">
              Company{" "}
            </label>
            <div class="col-sm-10">
              <input
                type="text"
                name="company"
                className="form-control"
                placeholder="Company Name"
                value={company}
                style={{ width: "300px" }}
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
          </div> */}
          <div class="form-group row">
            <label for="planName" class="col-sm-2 col-form-label">
              Plan Name{" "}
            </label>
            <div class="col-sm-10">
              <input
                type="text"
                name="planName"
                className="form-control"
                placeholder="Plan Name"
                value={planName}
                style={{ width: "300px" }}
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="chargerCount" class="col-sm-2 col-form-label">
              Chargers
            </label>
            <div class="col-sm-10">
              <input
                type="number"
                className="form-control"
                placeholder="Allowed Chargers Limit"
                name="chargerCount"
                value={chargerCount}
                style={{ width: "300px" }}
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="cpoCount" class="col-sm-2 col-form-label">
              Charger Operators{" "}
            </label>
            <div class="col-sm-10">
              <input
                type="number"
                className="form-control"
                placeholder="Allowed Charger Operators Limit"
                name="cpoCount"
                style={{ width: "300px" }}
                value={cpoCount}
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="description" class="col-sm-2 col-form-label">
              Description
            </label>
            <div class="col-sm-10">
              <textarea
                type="text"
                className="form-control"
                placeholder="Enter Plan Description"
                name="description"
                style={{ width: "300px", height: "100px" }}
                value={description}
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
          </div>
          <button className="btn btn-secondary">Add</button>
        </form>
      </div>
    </div>
  );
};

export default CreatePlan;
