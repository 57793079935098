import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaCheckDouble } from "react-icons/fa";
import { MdOutlineError } from "react-icons/md";

function PopUp({ isVisible = true, type = "success" }) {
  const [show, setShow] = useState(isVisible);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
    >
      {/* <Modal.Header>
        <Modal.Title>Success</Modal.Title>
      </Modal.Header> */}
      {type === "success" && (
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center flex-column p-2">
            <FaCheckDouble color="green" size={40} />
            <div className="h3 mt-2">Success</div>
          </div>
        </Modal.Body>
      )}
      {type === "failure" && (
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center flex-column p-2">
            <MdOutlineError color="red" size={40} />
            <div className="h3 mt-2">Failed</div>
          </div>
        </Modal.Body>
      )}
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          OK
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default PopUp;
