import React, { useState } from "react";
import axios from "axios";
import { useHistory, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Login from "./Login";

const Logout = () => {
  let history = useHistory();
  const requestToken = sessionStorage.getItem("token");

  // if(requestToken==null){
  //     alert("Session is expired");
  // }
  // axios.post("/logout",null,{ headers: {"Authorization" : `Bearer ${requestToken}`} });
  sessionStorage.clear();
  history.push("/login");
};

export default withRouter(Logout);
