import React, { useState } from "react";
import { createCompanyApi } from "../../../API/methods/companiesFlow";
import { useHistory, Link } from "react-router-dom";
import Navbar from "../../layout/Navbar";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useUpdateIsLoading } from "../../../Context/IsLoadingContext";
import { validateEmail } from "../../../util";

const CreateCompany = () => {
  const updateIsLoading = useUpdateIsLoading();
  let history = useHistory();
  const [company, setCompany] = useState({
    name: "",
    domainName: "",
    description: "",
    imageURL: "",
    active: false,
    paymentDetails: "",
    amount: "",
    receivedBy: "",
    email: "",
    address: {
      buildingNumber: "",
      street: "",
      city: "",
      state: "",
      code: "",
      country: "",
      phoneNumber: "",
      faxNumber: "",
      emailId: "",
      mobileNumber: "",
      type: "",
    },
  });
  const [validation, setValidation] = useState({
    name: false,
    domainName: false,
    description: false,
    imageURL: false,
    active: true,
    paymentDetails: false,
    amount: false,
    receivedBy: false,
    email: false,
    address: {
      buildingNumber: false,
      street: false,
      city: false,
      state: false,
      code: false,
      country: false,
      phoneNumber: false,
      faxNumber: false,
      emailId: false,
      mobileNumber: false,
      type: false,
    },
  });

  const {
    name,
    domainName,
    description,
    imageURL,
    email,
    active,
    amount,
    receivedBy,
    paymentDetails,
    address,
  } = company;

  const validatonCheck = (eventName, eventValue) => {
    if (eventName == "email") {
      setValidation({ ...validation, [eventName]: validateEmail(eventValue) });
      return;
    }
    if (eventValue == "") {
      setValidation({ ...validation, [eventName]: false });
    } else {
      setValidation({ ...validation, [eventName]: true });
    }
  };

  const validatonAddress = (eventName, eventValue) => {
    if (eventName == "emailId") {
      setValidation({
        ...validation,
        address: {
          ...validation.address,
          [eventName]: validateEmail(eventValue),
        },
      });
      return;
    }
    if (eventValue == "") {
      setValidation({
        ...validation,
        address: {
          ...validation.address,
          [eventName]: false,
        },
      });
    } else {
      setValidation({
        ...validation,
        address: {
          ...validation.address,
          [eventName]: true,
        },
      });
    }
  };

  const onInputChange = (e) => {
    const eventValue = e.target.value;
    const eventName = e.target.name;
    if (eventName === "active") {
      setCompany({ ...company, [e.target.name]: e.target.checked });
    } else {
      validatonCheck(eventName, eventValue);
      setCompany({ ...company, [e.target.name]: e.target.value });
    }
  };

  function onAddressChange(event) {
    const eventValue = event.target.value;
    const eventName = event.target.name;
    validatonAddress(eventName, eventValue);
    setCompany((prevState) => {
      if ("buildingNumber" === eventName) {
        prevState.address.buildingNumber = eventValue;
      } else if ("street" === eventName) {
        prevState.address.street = eventValue;
      } else if ("city" === eventName) {
        prevState.address.city = eventValue;
      } else if ("state" === eventName) {
        prevState.address.state = eventValue;
      } else if ("code" === eventName) {
        prevState.address.code = eventValue;
      } else if ("country" === eventName) {
        prevState.address.country = eventValue;
      } else if ("phoneNumber" === eventName) {
        prevState.address.phoneNumber = eventValue;
      } else if ("faxNumber" === eventName) {
        prevState.address.faxNumber = eventValue;
      } else if ("emailId" === eventName) {
        prevState.address.emailId = eventValue;
      } else if ("mobileNumber" === eventName) {
        prevState.address.mobileNumber = eventValue;
      } else if ("type" === eventName) {
        prevState.address.type = eventValue;
      }

      return {
        ...prevState,
        eventName: eventValue,
      };
    });
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    updateIsLoading(true);
    createCompanyApi(company)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          updateIsLoading(false);
          alert("Company added successfully");
          history.push("/companies");
        }
      })
      .catch((err) => {
        updateIsLoading(false);
        alert("Failed");
      });
  };

  const checkAllValidation = () => {
    if (company.name == "") {
    }
  };

  return (
    <div className="container mt-80">
      <Navbar></Navbar>
      <div className="w-150 mx-auto shadow p-5">
        <div className="d-flex flex-row">
          <Link to="/companies">
            <IoArrowBackCircleOutline size={35} color="#2bc87f" />
          </Link>
          <h3 className="text-center mb-4 ml-2">Company Details</h3>
        </div>
        <form onSubmit={(e) => onSubmit(e)}>
          <div class="form-group row">
            <h6 class="col-sm-4 col-form-label">Basic Details</h6>
          </div>
          <div className="row">
            <div className="col">
              <div class="form-group row  align-items-center">
                <label for="name" class="col-sm-3 col-form-label">
                  Company
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className={
                      validation.name
                        ? "form-control"
                        : "form-control is-invalid"
                    }
                    placeholder="Company"
                    name="name"
                    value={name}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                  {validation.name ? null : (
                    <div class="invalid-feedback">
                      Please enter the company name.
                    </div>
                  )}
                </div>
              </div>
              <div class="form-group row align-items-center">
                <label for="domainName" class="col-sm-3 col-form-label">
                  Domain
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className={
                      validation.domainName
                        ? "form-control"
                        : "form-control is-invalid"
                    }
                    placeholder="www.companyname.com"
                    name="domainName"
                    value={domainName}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                  />
                  {validation.domainName ? null : (
                    <div class="invalid-feedback">
                      Please enter the company website.
                    </div>
                  )}
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label for="description" class="col-sm-3 col-form-label">
                  Image URL
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control text-primary"
                    placeholder="https://www.example.com"
                    name="imageURL"
                    value={imageURL}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>
              <div class="form-group row align-items-center">
                <label for="email" class="col-sm-3 col-form-label">
                  E-mail
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className={
                      validation.email
                        ? "form-control"
                        : "form-control is-invalid"
                    }
                    placeholder="example@mail.com"
                    name="email"
                    value={email}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                  {validation.email ? null : (
                    <div class="invalid-feedback">
                      Please enter the valid company email.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col">
              <div class="form-group row align-items-top">
                <label for="description" class="col-sm-3 col-form-label">
                  Description
                </label>
                <div class="col-sm-9">
                  <textarea
                    type="text"
                    rows="3"
                    className={
                      validation.description
                        ? "form-control"
                        : "form-control is-invalid"
                    }
                    placeholder="Enter description"
                    name="description"
                    value={description}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                  {validation.description ? null : (
                    <div class="invalid-feedback">Describe about company.</div>
                  )}
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label for="active" class="col-sm-3 col-form-label">
                  Active
                </label>
                <div className="col-sm-9">
                  <input
                    type="checkbox"
                    name="active"
                    value={active}
                    style={{ width: 30 }}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mt-4">
            <h6 class="col-sm-4 col-form-label">Address Information</h6>
          </div>
          <div className="row">
            <div className="col ">
              <div class="form-group row align-items-center">
                <label for="buildingNumber" class="col-sm-3 col-form-label">
                  Building No.
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className={
                      validation.address.buildingNumber
                        ? "form-control"
                        : "form-control is-invalid"
                    }
                    placeholder="Building No."
                    name="buildingNumber"
                    value={address.buildingNumber}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                  {validation.address.buildingNumber ? null : (
                    <div class="invalid-feedback">
                      Please enter the building number.
                    </div>
                  )}
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label for="emailId" class="col-sm-3 col-form-label">
                  E-mail
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className={
                      validation.address.emailId
                        ? "form-control"
                        : "form-control is-invalid"
                    }
                    placeholder="example@mail.com"
                    name="emailId"
                    value={address.emailId}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                  {validation.address.emailId ? null : (
                    <div class="invalid-feedback">
                      Please enter the valid company email.
                    </div>
                  )}
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label for="street" class="col-sm-3 col-form-label">
                  Street{" "}
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className={
                      validation.address.street
                        ? "form-control"
                        : "form-control is-invalid"
                    }
                    placeholder="Street"
                    name="street"
                    value={address.street}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                  {validation.address.street ? null : (
                    <div class="invalid-feedback">
                      Please enter the street/block/vill etc.
                    </div>
                  )}
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label for="city" class="col-sm-3 col-form-label">
                  City
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className={
                      validation.address.city
                        ? "form-control"
                        : "form-control is-invalid"
                    }
                    placeholder="City"
                    name="city"
                    value={address.city}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                  {validation.address.city ? null : (
                    <div class="invalid-feedback">Please enter the city.</div>
                  )}
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label for="state" class="col-sm-3 col-form-label">
                  State
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className={
                      validation.address.state
                        ? "form-control"
                        : "form-control is-invalid"
                    }
                    placeholder="State"
                    name="state"
                    value={address.state}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                  {validation.address.state ? null : (
                    <div class="invalid-feedback">Please enter the State.</div>
                  )}
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label for="code" class="col-sm-3 col-form-label">
                  Area Code{" "}
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className={
                      validation.address.code
                        ? "form-control"
                        : "form-control is-invalid"
                    }
                    placeholder="Area Code"
                    name="code"
                    value={address.code}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                  {validation.address.code ? null : (
                    <div class="invalid-feedback">
                      Please enter the area code/pincode.
                    </div>
                  )}
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label for="country" class="col-sm-3 col-form-label">
                  Country
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className={
                      validation.address.country
                        ? "form-control"
                        : "form-control is-invalid"
                    }
                    placeholder="Country Name"
                    name="country"
                    value={address.country}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                  {validation.address.country ? null : (
                    <div class="invalid-feedback">
                      Please enter the country in which company is located.
                    </div>
                  )}
                </div>
              </div>
              <div class="form-group row align-items-center">
                <label for="phoneNumber" class="col-sm-3 col-form-label">
                  Phone No.
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className={
                      validation.address.phoneNumber
                        ? "form-control"
                        : "form-control is-invalid"
                    }
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={address.phoneNumber}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                  {validation.address.phoneNumber ? null : (
                    <div class="invalid-feedback">
                      Please enter the company phone number.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col">
              <div class="form-group row align-items-center">
                <label for="faxNumber" class="col-sm-3 col-form-label">
                  Fax No.
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Fax Number"
                    name="faxNumber"
                    value={address.faxNumber}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                  />
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label for="type" class="col-sm-3 col-form-label">
                  Address Type
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className={
                      validation.address.type
                        ? "form-control"
                        : "form-control is-invalid"
                    }
                    placeholder="Address Type"
                    name="type"
                    value={address.type}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                  {validation.address.type ? null : (
                    <div class="invalid-feedback">
                      Please enter the address type. E.g. Business, Home,
                      Billing, Shipping, etc.
                    </div>
                  )}
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label for="mobileNumber" class="col-sm-3 col-form-label">
                  Mobile No.
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className={
                      validation.address.mobileNumber
                        ? "form-control"
                        : "form-control is-invalid"
                    }
                    placeholder="Mobile Number"
                    name="mobileNumber"
                    value={address.mobileNumber}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                  {validation.address.mobileNumber ? null : (
                    <div class="invalid-feedback">
                      Please enter mobile number.
                    </div>
                  )}
                </div>
              </div>
              <div class="form-group row align-items-center">
                <label for="amount" class="col-sm-3 col-form-label">
                  Amount
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Amount INR"
                    name="amount"
                    value={amount}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label for="receivedBy" class="col-sm-3 col-form-label">
                  Payment Received By
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Received By"
                    name="receivedBy"
                    value={receivedBy}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label for="paymentDetails" class="col-sm-3 col-form-label">
                  Payment Details
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Payment Details Via- cash,card,netbanking"
                    name="paymentDetails"
                    value={paymentDetails}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <button className="btn btn-primary btn-m mt-2">
            Add New Company
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateCompany;
