import React from "react";
import { Route, Redirect } from "react-router-dom";

function ProtectedRoute({ isAuth: isAuth, component: Component, ...rest }) {
  let isLogin = isUserLogin();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLogin) {
          return <Component />;
        } else {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
      }}
    />
  );
}

export const isUserLogin = () => {
  let isLogin = false;
  const token = sessionStorage.getItem("token");
  if (token) {
    isLogin = true;
  }
  return isLogin;
};

export default ProtectedRoute;
