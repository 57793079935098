import { apiCall, apiWithoutBaseCall } from "../../API";
import ApiConstants from "../ApiConstants";

export async function getPlanApi() {
  return apiCall(ApiConstants.PLANS, null, "GET");
}

export async function getPlanByIdApi(id) {
  return apiCall(`${ApiConstants.PLAN}/${id}`, null, "GET");
}

export async function createPlanApi(data) {
  return apiCall(ApiConstants.PLAN, data, "POST");
}

export async function updatePlanApi(id, data) {
  return apiCall(`${ApiConstants.PLAN}/${id}`, data, "PUT");
}

export async function deletePlanApi(id) {
  return apiCall(`${ApiConstants.PLAN}/${id}`, null, "DELETE");
}
