import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getPlanByIdApi } from "../../../API/methods/planFlow";
import Navbar from "../../layout/Navbar";
import { IoArrowBackCircleOutline } from "react-icons/io5";

const ViewPlanDetails = () => {
  const [plan, setPlan] = useState({
    planId: "",
    description: "",
    planName: "",
    chargerCount: "",
    cpoCount: "",
  });
  const { planId, description, planName, chargerCount, cpoCount } =
    plan;
  const { id } = useParams();
  useEffect(() => {
    loadPlan();
  }, []);
  const loadPlan = async () => {
    const result = await getPlanByIdApi(id);
    if (result) {
      setPlan(result.data);
    }
  };
  return (
    <div className="container  mt-80">
      <Navbar></Navbar>
      <div className="w-150 mx-auto shadow p-5">
        <div className="d-flex flex-row">
          <Link to="/plans">
            <IoArrowBackCircleOutline size={35} color="#2bc87f" />
          </Link>
          <h3 className="text-center mb-4 ml-2">Plan</h3>
        </div>
        <form>
          <tr>
            <div class="form-group row">
              <label for="planId" class="col-sm-4 col-form-label">
                Plan Id
              </label>
              <div class="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Plan Id"
                  name="planId"
                  value={planId}
                  style={{ width: "300px" }}
                  required
                  readOnly
                />
              </div>
            </div>


            <div class="form-group row">
              <label for="planName" class="col-sm-4 col-form-label">
                Plan Name
              </label>
              <div class="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Plan Name"
                  name="planName"
                  value={planName}
                  style={{ width: "300px" }}
                  required
                  readOnly
                />
              </div>
            </div>

            <div class="form-group row">
              <label for="description" class="col-sm-4 col-form-label">
                Description
              </label>
              <div class="col-sm-8">
                <textarea
                  type="text"
                  rows={3}
                  className="form-control"
                  placeholder="Plan Discription"
                  name="description"
                  value={description}
                  style={{ width: "300px" }}
                  readOnly
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="chargerCount" class="col-sm-4 col-form-label">
                Charger Count
              </label>
              <div class="col-sm-8">
                <input
                  type="chargerCount"
                  className="form-control"
                  placeholder="Number of charger Count"
                  name="chargerCount"
                  value={chargerCount}
                  style={{ width: "300px" }}
                  required
                  readOnly
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="cpoCount" class="col-sm-4 col-form-label">
                Charger Operators
              </label>
              <div class="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Number of Cpu Count"
                  name="cpoCount"
                  value={cpoCount}
                  style={{ width: "300px", height: "60px" }}
                  required
                  readOnly
                />
              </div>
            </div>
          </tr>
        </form>
      </div>
    </div>
  );
};
export default ViewPlanDetails;
