import ApiConstants from "./ApiConstants";
import axios from "axios";

export function apiCall(path, params, method) {
  const token = localStorage.getItem("token");
  let options;
  options = {
    method: method,
    url: ApiConstants.BASE_URL + path,
    ...(params && { data: params }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  }
  return axios(options)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      alert(error);
    });
}

export function apiWithoutBaseCall(path, params, method) {
  const token = localStorage.getItem("token");
  let options;
  options = {
    method: method,
    url: path,
    ...(params && { data: params }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  };
  return axios(options)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      alert(error);
    });
}
