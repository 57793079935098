const ApiConstants = {
  BASE_URL: "https://www.evitonline.com",

  AUTH: {
    LOGIN: "/authenticate",
    LOGOUT: "/logout",
  },
  COMPANIES: "/cms/companies",
  SEND_EMAIL: "/cms/companies/sendEmail/",
  PLAN: "/cms/provisioning/plan",
  PLANS: "/cms/provisioning/plans",
  USER: {
    GET_ADMIN: "/cms/user/getAllCompanyAdmins",
    ADD_ADMIN: "/cms/user/createUser",
  },
};

export default ApiConstants;
