import React, { useState, useEffect } from "react";
import {
  updateCompanyApi,
  deleteCompanyApi,
  getCompanyByIdApi,
} from "../../../API/methods/companiesFlow";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar from "../../layout/Navbar";
import "../../../login.css";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useUpdateIsLoading } from "../../../Context/IsLoadingContext";

const EditCompany = () => {
  let history = useHistory();
  const updateIsLoading = useUpdateIsLoading();
  const { id } = useParams();
  const [company, setCompany] = useState({
    name: "",
    domainName: "",
    description: "",
    imageURL: "",
    active: false,
    email: "",
    expire: "",
    paymentDetails: "",
    amount: "",
    receivedBy: "",
    address: {
      buildingNumber: "",
      street: "",
      city: "",
      state: "",
      code: "",
      country: "",
      phoneNumber: "",
      faxNumber: "",
      emailId: "",
      mobileNumber: "",
      type: "",
    },
  });
  const {
    expire,
    name,
    domainName,
    description,
    imageURL,
    address,
    buildingNumber,
    street,
    city,
    state,
    email,
    active,
    code,
    country,
    phoneNumber,
    faxNumber,
    emailId,
    mobileNumber,
    type,
    paymentDetails,
    amount,
    receivedBy,
  } = company;
  useEffect(() => {
    loadCompany();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log('Request: ',company)
    const result = await updateCompanyApi(name, company);
    // console.log('Response : ',result);
    if (result.status == 200) {
      alert("Successfully Updated Company");
    }
    setCompany(result.data);
    history.push("/companies");
  };
  const deleteCompany = async (name) => {
    if (
      window.confirm("Are you sure you want to delete this company") === true
    ) {
      const result = await deleteCompanyApi(name);
      if (result.status == 200) {
        alert("Successfully Deleted Company");
      } else {
        alert("Error occurred while deleting company ");
      }
      history.push("/companies");
    }
  };
  const loadCompany = async () => {
    updateIsLoading(true);
    getCompanyByIdApi(id)
      .then((res) => {
        if (res.status === 200) {
          setCompany(res.data);
        }
      })
      .catch(() => {
        window.alert("Internal Server Error");
      })
      .finally(() => {
        updateIsLoading(false);
      });
  };
  const onInputChange = (e) => {
    if (e.target.name === "status") {
      company.active = e.target.checked;
    }
    setCompany({ ...company, [e.target.name]: e.target.value });
  };
  function onAddressChange(event) {
    const eventValue = event.target.value;
    const eventName = event.target.name;
    setCompany((prevState) => {
      if ("buildingNumber" === eventName) {
        prevState.address.buildingNumber = eventValue;
      } else if ("street" === eventName) {
        prevState.address.street = eventValue;
      } else if ("city" === eventName) {
        prevState.address.city = eventValue;
      } else if ("state" === eventName) {
        prevState.address.state = eventValue;
      } else if ("code" === eventName) {
        prevState.address.code = eventValue;
      } else if ("country" === eventName) {
        prevState.address.country = eventValue;
      } else if ("phoneNumber" === eventName) {
        prevState.address.phoneNumber = eventValue;
      } else if ("faxNumber" === eventName) {
        prevState.address.faxNumber = eventValue;
      } else if ("emailId" === eventName) {
        prevState.address.emailId = eventValue;
      } else if ("mobileNumber" === eventName) {
        prevState.address.mobileNumber = eventValue;
      } else if ("type" === eventName) {
        prevState.address.type = eventValue;
      }
      return {
        ...prevState,
        address: { ...prevState.address, [eventName]: eventValue },
      };
    });
  }

  return (
    <div className="container mt-80">
      <Navbar></Navbar>
      <div className="w-100 mx-auto dark shadow p-5 ">
        <div className="d-flex flex-row">
          <Link to="/companies">
            <IoArrowBackCircleOutline size={35} color="#2bc87f" />
          </Link>
          <h3 className="text-center mb-4 ml-2">Update</h3>
        </div>
        <form onSubmit={(e) => onSubmit(e)}>
          <div class="form-group row">
            <h6 class="col-sm-2 col-form-label">Basic Details</h6>
          </div>
          <div className="row">
            <div className="col">
              <div class="form-group row row align-items-center">
                <label for="name" class="col-sm-3 col-form-label">
                  Company
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Company"
                    name="name"
                    value={name}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                    readOnly
                  />
                </div>
              </div>

              <div class="form-group row row align-items-center">
                <label for="domainName" class="col-sm-3 col-form-label">
                  Domain
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="www.companyname.com"
                    name="domainName"
                    value={domainName}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>

              <div class="form-group row align-items-center">
                <label for="description" class="col-sm-3 col-form-label">
                  Image URL
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control text-primary"
                    placeholder="https://www.example.com"
                    name="imageURL"
                    value={imageURL}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>

              <div class="form-group row row align-items-center">
                <label for="email" class="col-sm-3 col-form-label">
                  E-mail
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="example@mail.com"
                    name="email"
                    value={email}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div class="form-group row row align-items-top">
                <label for="description" class="col-sm-3 col-form-label">
                  Description:{" "}
                </label>
                <div class="col-sm-9">
                  <textarea
                    type="text"
                    rows={3}
                    className="form-control"
                    placeholder="Enter description"
                    name="description"
                    value={description}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
              <div class="form-group row row align-items-center">
                <label for="active" class="col-sm-3 col-form-label">
                  Active
                </label>
                <div class="col-sm-9">
                  <input
                    type="checkbox"
                    name="status"
                    value="status"
                    checked={active}
                    style={{ width: 30 }}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mt-4">
            <h6 class="col-sm-4 col-form-label">Address Information</h6>
          </div>
          <div className="row">
            <div className="col ">
              <div class="form-group row row align-items-center">
                <label for="buildingNumber" class="col-sm-3 col-form-label">
                  Building No.{" "}
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Building number"
                    name="buildingNumber"
                    value={address.buildingNumber}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                </div>
              </div>

              <div class="form-group row row align-items-center">
                <label for="emailId" class="col-sm-3 col-form-label">
                  E-mail
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="example@mail.com"
                    name="emailId"
                    value={address.emailId}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                </div>
              </div>

              <div class="form-group row row align-items-center">
                <label for="street" class="col-sm-3 col-form-label">
                  Street{" "}
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street name"
                    name="street"
                    value={address.street}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                </div>
              </div>

              <div class="form-group row row align-items-center">
                <label for="city" class="col-sm-3 col-form-label">
                  City
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    name="city"
                    value={address.city}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                </div>
              </div>

              <div class="form-group row row align-items-center">
                <label for="state" class="col-sm-3 col-form-label">
                  State
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State"
                    name="state"
                    value={address.state}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                </div>
              </div>

              <div class="form-group row row align-items-center">
                <label for="code" class="col-sm-3 col-form-label">
                  Area Code{" "}
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Area Code"
                    name="code"
                    value={address.code}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                </div>
              </div>

              <div class="form-group row row align-items-center">
                <label for="country" class="col-sm-3 col-form-label">
                  Country
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Country Name"
                    name="country"
                    value={address.country}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                </div>
              </div>

              <div class="form-group row row align-items-center">
                <label for="phoneNumber" class="col-sm-3 col-form-label">
                  Phone No.
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={address.phoneNumber}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div class="form-group row row align-items-center">
                <label for="faxNumber" class="col-sm-3 col-form-label">
                  Fax No.
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Fax Number"
                    name="faxNumber"
                    value={address.faxNumber}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                  />
                </div>
              </div>

              <div class="form-group row row align-items-center">
                <label for="type" class="col-sm-3 col-form-label">
                  Address Type
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address Type"
                    name="type"
                    value={address.type}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                </div>
              </div>

              <div class="form-group row row align-items-center">
                <label for="mobileNumber" class="col-sm-3 col-form-label">
                  Mobile No.
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile Number"
                    name="mobileNumber"
                    value={address.mobileNumber}
                    style={{ width: "300px" }}
                    onChange={(e) => onAddressChange(e)}
                    required
                  />
                </div>
              </div>

              <div class="form-group row row align-items-center">
                <label for="amount" class="col-sm-3 col-form-label">
                  Amount
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Amount in INR"
                    name="amount"
                    value={amount}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>

              <div class="form-group row row align-items-center">
                <label for="paymentDetails" class="col-sm-3 col-form-label">
                  Payment Details
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Payment Details Via- cash,card,netbanking*"
                    name="paymentDetails"
                    value={paymentDetails}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>

              <div class="form-group row row align-items-center">
                <label for="receivedBy" class="col-sm-3 col-form-label">
                  Received By
                </label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Payment Received By"
                    name="receivedBy"
                    value={receivedBy}
                    style={{ width: "300px" }}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <button className="btn btn-light btn-sm mt-4">Update</button>
          <Link
            class="btn btn-danger btn-sm mt-4"
            onClick={() => deleteCompany(company.name)}
          >
            Delete
          </Link>
        </form>
      </div>
    </div>
  );
};

export default EditCompany;
