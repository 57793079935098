import React, { useState, useEffect } from "react";
import { getCompanyApi } from "../../../API/methods/companiesFlow";
import { Link } from "react-router-dom";
import Navbar from "../../layout/Navbar";
import "../../../index.css";
import { useUpdateIsLoading } from "../../../Context/IsLoadingContext";

const CompaniesManagement = () => {
  const updateIsLoading = useUpdateIsLoading();
  const [companies, setCompany] = useState([]);
  useEffect(() => {
    loadCompanies();
  }, []);

  const loadCompanies = async () => {
    updateIsLoading(true);
    const result = await getCompanyApi();
    if (result) {
      setCompany(result.data);
    }
    updateIsLoading(false);
  };

  return (
    <div className="container mt-80">
      <Navbar></Navbar>
      {/* <h5 className="heading">Companies</h5> */}
      <div className="py-4">
        {companies.length === 0 && (
          <Link class="btn btn-light btn-sm" to={`/addnewcompany`}>
            Add
          </Link>
        )}
        {companies.length !== 0 && (
          <table class="table border shadow">
            <thead class="thead-light">
              <tr>
                <th scope="col">Company</th>
                <th scope="col">Domain</th>
                {/* <th scope="col">Description</th> */}
                <th scope="col">Status</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company, index) => (
                <tr>
                  <td className="w-10">{company.name}</td>
                  <td className="w-20">{company.domainName}</td>
                  {/* <td className="w-20">{company.description}</td> */}
                  <td className="w-10">
                    {company.active.toString() === "true" ? (
                      <b>
                        <font color="ForestGreen">Active</font>
                      </b>
                    ) : (
                      <b>
                        <font color="Red">Inactive</font>
                      </b>
                    )}
                  </td>
                  <td className="w-40">
                    <Link
                      class="btn btn-light btn-sm"
                      to={`/viewcompany/${company.name}`}
                    >
                      View{" "}
                    </Link>
                    <Link
                      class="btn btn-light btn-sm"
                      to={`/editcompany/${company.name}`}
                    >
                      Update{" "}
                    </Link>
                    <Link
                      class="btn btn-light btn-sm"
                      to={`/addadmin/${company.name}`}
                    >
                      Add Admin{" "}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
            <tr>
              <td>
                <Link class="btn btn-light btn-sm" to={`/addnewcompany`}>
                  +
                </Link>
              </td>
            </tr>
          </table>
        )}
      </div>
    </div>
  );
};

export default CompaniesManagement;
