import React, { useState, useEffect } from "react";
import { getAllAdminApi } from "../../../API/methods/adminFlow";
import { Link } from "react-router-dom";
import Navbar from "../../layout/Navbar";
import "../../../index.css";
import { useUpdateIsLoading } from "../../../Context/IsLoadingContext";

const ViewAllAdmins = () => {
  const updateIsLoading = useUpdateIsLoading();
  const [Admins, setAdmins] = useState([]);
  useEffect(() => {
    loadAdmins();
  }, []);

  const loadAdmins = async () => {
    updateIsLoading(true);
    const result = await getAllAdminApi();
    console.log("abc  ",result.data)
    setAdmins(result.data);
    updateIsLoading(false);
  };

  return (
    <div className="container mt-80">
      <Navbar></Navbar>
      {/* <h3 className="heading">Admins</h3> */}
      <div className="py-4">
        <table class="table border shadow">
          <thead class="thead-light">
            <tr>
              <th scope="col">Company</th>
              <th scope="col">Email</th>
              <th scope="col">Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Admins.map((Admins, index) => (
              <tr>
                <td>{Admins.company}</td>
                <td>{Admins.email}</td>
                <td>{Admins.status}</td>
              </tr>
            ))}
          </tbody>
          {/* <tr>
            <td>

            <Link className="btn btn-light btn-sm" to="/companies">Go back  </Link>
            </td>
          </tr> */}
        </table>
      </div>
    </div>
  );
};

export default ViewAllAdmins;
