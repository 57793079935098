import React, { useState, useEffect } from "react";
import {
  updatePlanApi,
  deletePlanApi,
  getPlanByIdApi,
} from "../../../API/methods/planFlow";
import { useHistory, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar from "../../layout/Navbar";
import { IoArrowBackCircleOutline } from "react-icons/io5";

const EditPlan = () => {
  let history = useHistory();
  const { id } = useParams();
  const [plan, setPlan] = useState({
    planId: "",
    planName: "",
    description: "",
    chargerCount: "",
    cpoCount: "",
  });
  const { planId, description, planName, chargerCount, cpoCount } =
    plan;
  useEffect(() => {
    loadPlan();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const result = await updatePlanApi(id, plan);
    setPlan(result.data);
    history.push("/plans");
  };
  const deletePlan = async (name) => {
    const deletPlanConfirmation = window.confirm(
      "Are you sure you want to delete this plan"
    );
    if (deletPlanConfirmation == true) {
      const result = await deletePlanApi(id);
      if (result.status == 200) {
        alert(" Plan [" + { id } + "] was successfully deleted");
      } else {
        alert("Error occurred while deleting Plan ");
      }
    }
    history.push("/plans");
  };
  const loadPlan = async () => {
    const result = await getPlanByIdApi(id);
    setPlan(result.data);
  };
  const onInputChange = (e) => {
    setPlan({ ...plan, [e.target.name]: e.target.value });
  };
  return (
    <div className="container mt-80">
      <Navbar></Navbar>
      <div className="w-150 mx-auto shadow p-5">
        <div className="d-flex flex-row">
          <Link to="/plans">
            <IoArrowBackCircleOutline size={35} color="#2bc87f" />
          </Link>
          <h3 className="text-center mb-4 ml-2">Update</h3>
        </div>
        <form onSubmit={(e) => onSubmit(e)}>
          <div class="form-group row">
            <label for="planName" class="col-sm-2 col-form-label">
              Plan Name
            </label>
            <div class="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="Plan Name"
                name="planName"
                value={planName}
                style={{ width: "300px" }}
                onChange={(e) => onInputChange(e)}
                required
                readOnly
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="description" class="col-sm-2 col-form-label">
              Description
            </label>
            <div class="col-sm-10">
              <textarea
                type="text"
                rows={3}
                className="form-control"
                placeholder="Enter description"
                name="description"
                value={description}
                style={{ width: "300px" }}
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="chargerCount" class="col-sm-2 col-form-label">
              Charger Count{" "}
            </label>
            <div class="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Charger Count"
                name="chargerCount"
                value={chargerCount}
                style={{ width: "300px" }}
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="cpoCount" class="col-sm-2 col-form-label">
              Chargers operator
            </label>
            <div class="col-sm-10">
              <input
                type="text"
                className="form-control"
                placeholder="Number of Charger operator"
                name="cpoCount"
                value={cpoCount}
                style={{ width: "300px" }}
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
          </div>
          <button className="btn btn-light btn-sm">Update</button>
          <Link
            class="btn btn-danger btn-sm"
            onClick={() => deletePlan(plan.planId)}
          >
            Delete
          </Link>
        </form>
      </div>
    </div>
  );
};

export default EditPlan;
