import React, { useContext, useState } from "react";

const IsLoadingContext = React.createContext();
const IsLoadingUpdateContext = React.createContext();

export function useIsLoading() {
  return useContext(IsLoadingContext);
}
export function useUpdateIsLoading() {
  return useContext(IsLoadingUpdateContext);
}

export function IsLoadingProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  function updateIsLoading(value) {
    setIsLoading(value);
  }

  return (
    <IsLoadingContext.Provider value={isLoading}>
      <IsLoadingUpdateContext.Provider value={updateIsLoading}>
        {children}
      </IsLoadingUpdateContext.Provider>
    </IsLoadingContext.Provider>
  );
}
