import React, { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import {
  Navbar,
  Button,
  Nav,
  Form,
  NavDropdown,
  FormControl,
  Container,
  Modal,
} from "react-bootstrap";
import "../../index.css";
import PopUp from "../PopUp";
import { getUserDataFromLocalStorage } from "../../util";
import { isUserLogin } from "../pages/ProtectedRoute";

const Navbar1 = () => {
  const [show, setShow] = useState(false);
  const user = getUserDataFromLocalStorage();
  const isLogin = isUserLogin();
  let history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    history.push("/login");
  };

  return (
    <Navbar variant="dark" expand="xl" fixed="top" className="color-nav">
      <Container>
        <Navbar.Brand href="/companies" bsPrefix="cmpLogo mr-5">
          <img
            alt="Evi"
            // src="https://evitpllogo.s3.ap-south-1.amazonaws.com/download.png"
            src={require('../../assets/Images/EVILogo.png')}
            width="45"
            height="45"
            className="d-inline-block mr-2 pb-1"
          />
          EVI Technologies
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link
              href="/companies"
              bsPrefix={
                history.location.pathname === "/companies" ||
                history.location.pathname === "/addnewcompany" ||
                history.location.pathname.indexOf("/editcompany/") !== -1 ||
                history.location.pathname.indexOf("/viewcompany/") !== -1
                  ? "font-weight-bold h5 text-secondary align-self-center mx-2"
                  : ""
              }
            >
              Companies
            </Nav.Link>
            <Nav.Link
              href="/plans"
              bsPrefix={
                history.location.pathname === "/plans" ||
                history.location.pathname.indexOf("/editplan/") !== -1 ||
                history.location.pathname.indexOf("/viewplan/") !== -1
                  ? "font-weight-bold h5 text-secondary align-self-center mx-2"
                  : ""
              }
            >
              Plans
            </Nav.Link>
            <Nav.Link
              href="/subscriptions"
              bsPrefix={
                history.location.pathname === "/subscriptions"
                  ? "font-weight-bold h5 text-secondary align-self-center mx-2"
                  : ""
              }
            >
              Subscriptions
            </Nav.Link>
            <Nav.Link
              href="/viewAllAdmins"
              bsPrefix={
                history.location.pathname === "/viewAllAdmins"
                  ? "font-weight-bold h5 text-secondary align-self-center mx-2"
                  : ""
              }
            >
              Admins
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        {/* <NavLink className="btn btn-light btn-sm" exact to="/logout" >
          <b>Logout</b>
        </NavLink> */}
        <div className="btn btn-light btn-sm" onClick={handleShow}>
          <b>Logout</b>
        </div>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header
          closeButton
          bsPrefix={"border-0 d-flex px-3 mt-2 justify-content-between"}
        >
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you Sure you want to logout?</Modal.Body>
        <Modal.Footer bsPrefix={"border-0 d-flex justify-content-end mb-3 mr-2"}>
          <Button variant="danger" bsPrefix={""} onClick={handleLogout}>
            Logout
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Navbar>
  );
};
export default Navbar1;
