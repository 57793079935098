import CryptoJS, { AES } from "crypto-js";

export function setUserDataToLocalStorage(data) {
  // Encrypt
  let SECRET_KEY = data.token;
  let ciphertext = AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  localStorage.setItem("user", ciphertext);
}

export function getUserDataFromLocalStorage() {
  const token = localStorage.getItem("token");
  const ciphertext = localStorage.getItem("user");
  if (ciphertext === null) return null;
  // Decrypt
  let SECRET_KEY = token;
  let bytes = AES.decrypt(ciphertext, SECRET_KEY);
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}

export function validateEmail(email) {
  let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

export function validatePassword(password) {
  if (password.length < 4) {
    return false;
  }
  return true;
}
