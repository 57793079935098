import React, { useState } from "react";
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
  Form,
  Spinner,
} from "react-bootstrap";
import "../../login.css";
import { loginApi } from "../../API/methods/authFlow";
import { useHistory } from "react-router-dom";
import { setUserDataToLocalStorage } from "../../util";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

export default function Login() {
  let history = useHistory();
  const [name, setUserName] = useState("");
  const [pwd, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  
  function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    const user = {
      username: name,
      password: pwd,
    };
    loginApi(user)
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem("token", res.data.token);
          localStorage.setItem("token", res.data.token);
          setUserDataToLocalStorage(res.data);
          setIsLoading(false);
          history.push("/companies");
        } else if (res.status === 403) {
          setIsLoading(false);
          alert("Invalid Credentials");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        alert(`Error: ${error.message}`);
      });
  }

  return (
    <body>
      <div className="Login">
        <div className="LoginBox shadow">
          <div className="info">
            {/* <h4 className="login-header text-center mt-2">
              Provisioning App
            </h4> */}
            <img
              alt="Evi"
              src="https://evitpllogo.s3.ap-south-1.amazonaws.com/download.png"
              width="240"
              height="180"
            />
          </div>
          <form onSubmit={handleSubmit}>
            <h5 className="login-header text-center mt-2">Login</h5>
            <FormGroup controlId="userName" bsSize="large" className="mt-2">
              <Form.Label>User Name</Form.Label>
              <FormControl
                autoFocus
                type="text"
                placeholder="User Name"
                required
                value={name}
                onChange={(e) => setUserName(e.target.value)}
                style={{ width: 250 }}
              />
            </FormGroup>
            <FormGroup controlId="password" bsSize="large">
              <Form.Label>Password</Form.Label>
              <InputGroup
                style={{ width: 250 }}
                className="d-flex justify-content-end"
              >
                <FormControl
                  value={pwd}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  type={isVisible ? "text" : "password"}
                  style={{ paddingRight: 50 }}
                  required
                />
                {isVisible ? (
                  <AiFillEyeInvisible
                    onClick={() => setIsVisible(!isVisible)}
                    className="password-icon"
                  />
                ) : (
                  <AiFillEye
                    onClick={() => setIsVisible(!isVisible)}
                    className="password-icon"
                  />
                )}
              </InputGroup>
            </FormGroup>
            <button type="submit" className="btn mt-2 btn-color">
              Login
              {isLoading && (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  className="ml-1"
                />
              )}
            </button>
          </form>
        </div>
      </div>
    </body>
  );
}
