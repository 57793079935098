import { apiCall, apiWithoutBaseCall } from "../../API";
import ApiConstants from "../ApiConstants";

export async function getCompanyApi() {
  return apiCall(ApiConstants.COMPANIES, null, "GET");
}

export async function getCompanyByIdApi(id) {
  return apiCall(`${ApiConstants.COMPANIES}/${id}`, null, "GET");
}

export async function createCompanyApi(data) {
  return apiCall(ApiConstants.COMPANIES, data, "POST");
}

export async function updateCompanyApi(name, data) {
  return apiCall(`${ApiConstants.COMPANIES}/${name}`, data, "PUT");
}

export async function deleteCompanyApi(name) {
  return apiCall(`${ApiConstants.COMPANIES}/${name}`, null, "DELETE");
}

export async function sendEmailApi(id, company) {
  return apiCall(`${ApiConstants.SEND_EMAIL}${id}`, company, "POST");
}
