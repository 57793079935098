import React from "react";
import "./Loader.css";
import { Modal, Spinner } from "react-bootstrap";

function Loader() {
  return (
    // <div className="spinnerContainer d-flex justify-content-center align-items-center">
    //   <div className="spinnerBox d-flex justify-content-center align-items-center">
    //     <Spinner animation="border" variant="success" />
    //     <div className="loadingTxt">Loading...</div>
    //   </div>
    // </div>

    <Modal
      show={true}
      onHide={() =>{}}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
    >
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center flex-column p-2">
          <Spinner animation="border" variant="success" />
          <div className="loadingTxt h4 mt-2">Loading...</div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Loader;
