import React, { useState, useEffect } from "react";
import { getPlanApi } from "../../../API/methods/planFlow";
import { Link } from "react-router-dom";
import Navbar from "../../layout/Navbar";
import { useUpdateIsLoading } from "../../../Context/IsLoadingContext";
const SubscriptionManagement = () => {
  const updateIsLoading = useUpdateIsLoading();
  const [plans, setPlans] = useState([]);
  useEffect(() => {
    loadPlans();
  }, []);

  const loadPlans = async () => {
    updateIsLoading(true);
    const result = await getPlanApi();
    if (result) {
      setPlans(result.data);
    }
    updateIsLoading(false);
  };
  return (
    <div className="container  mt-80">
      <Navbar></Navbar>
      {/* <h5 className="heading">Plans</h5> */}
      <div className="py-4">
        {plans.length === 0 && (
          <Link class="btn btn-light btn-smx" to={`/addPlan`}>
            Add
          </Link>
        )}
        {plans.length !== 0 && (
          <table class="table border shadow ">
            <thead class="thead-light">
              <tr>
                <th scope="col">Plan Id</th>
                <th scope="col">Plan Name</th>
                <th scope="col">Chargers</th>
                <th scope="col">Charger Operators</th>
                <th scope="col">Description</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {plans.map((plan, index) => (
                <tr>
                  <td>{plan.planId}</td>
                  <td>{plan.planName}</td>
                  <td>{plan.chargerCount}</td>
                  <td>{plan.cpoCount}</td>
                  <td>{plan.description}</td>
                  <td>
                    <Link
                      class="btn btn-light btn-sm"
                      to={`/viewplan/${plan.planId}`}
                    >
                      View{" "}
                    </Link>
                    <Link
                      class="btn btn-light btn-sm"
                      to={`/editplan/${plan.planId}`}
                    >
                      Edit{" "}
                    </Link>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <Link class="btn btn-light btn-smx" to={`/addPlan`}>
                    +
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default SubscriptionManagement;
