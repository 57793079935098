import { apiCall, apiWithoutBaseCall } from "../../API";
import ApiConstants from "../ApiConstants";

export async function getAllAdminApi() {
  return apiCall(ApiConstants.USER.GET_ADMIN, null, "GET");
}

export async function createAdminApi(data) {
  return apiCall(ApiConstants.USER.ADD_ADMIN, data, "POST");
}
